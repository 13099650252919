<template>
    <v-container>
        <v-card width="100%">
            <v-card-title class="text-h5">
                <v-icon left>mdi-alert</v-icon>
                Reportar incidencia
            </v-card-title>
            <v-card-text>

                <p class="text-h6">
                    Si ha encontrado algún problema en la aplicación, por favor, reportelo aquí. Nos pondremos en
                    contacto con usted lo antes posible.
                </p>

                <p class="text-h6">
                    Guia para reportar incidencias:
                </p>
                <v-alert type="info" text>
                    <ul>
                        <li>Describa el problema de la forma más detallada posible.</li>
                        <li>Cuando ocurrió el problema, si fue durante una acción específica o en una pantalla</li>
                        <li>Si el problema es recurrente, indique los pasos para reproducirlo.</li>
                    </ul>
                </v-alert>

                <v-form ref="form">
                    <v-textarea
                        hide-details=""
                        class="rounded-lg"
                        filled
                        rounded
                        prepend-inner-icon="mdi-comment"
                        :rows="4"
                        auto-grow
                        v-model="comment"
                        label="Comentarios"
                    ></v-textarea>

                    <!-- File input for video or image -->
                    <v-file-input
                        rounded filled class="rounded-lg mt-4"
                        v-model="file"
                        hide-details=""
                        label="Subir imagen o video (opcional)"
                        accept="image/*,video/*"
                    ></v-file-input>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-2" @click="submitSurvey" :loading="loading" large :disabled="!comment">
                    <v-icon left class="mr-2">mdi-bug</v-icon>
                    Reportar problema
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import { collection, addDoc, getFirestore, serverTimestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Function to get user info
function getUserInfo() {
    const userInfo = {
        browser: getBrowserInfo(),
        os: getOSInfo(),
        screenSize: getScreenSize(),
        language: navigator.language,
        dateTime: new Date().toString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    return userInfo;
}

// Function to get browser info
function getBrowserInfo() {
    const ua = navigator.userAgent;
    let browserName = "Unknown";

    if (ua.indexOf("Firefox") > -1) {
        browserName = "Mozilla Firefox";
    } else if (ua.indexOf("SamsungBrowser") > -1) {
        browserName = "Samsung Internet";
    } else if (ua.indexOf("Opera") > -1 || ua.indexOf("OPR") > -1) {
        browserName = "Opera";
    } else if (ua.indexOf("Trident") > -1) {
        browserName = "Microsoft Internet Explorer";
    } else if (ua.indexOf("Edge") > -1) {
        browserName = "Microsoft Edge";
    } else if (ua.indexOf("Chrome") > -1) {
        browserName = "Google Chrome";
    } else if (ua.indexOf("Safari") > -1) {
        browserName = "Apple Safari";
    }

    return browserName;
}

// Function to get OS info
function getOSInfo() {
    const os = navigator.platform;
    let osName = "Unknown";

    if (os.indexOf("Win") > -1) {
        osName = "Windows";
    } else if (os.indexOf("Mac") > -1) {
        osName = "MacOS";
    } else if (os.indexOf("Linux") > -1) {
        osName = "Linux";
    } else if (os.indexOf("Android") > -1) {
        osName = "Android";
    } else if (os.indexOf("iOS") > -1) {
        osName = "iOS";
    }

    return osName;
}

// Function to get screen size
function getScreenSize() {
    return {
        width: screen.width,
        height: screen.height
    };
}

export default {
    computed: {
        filteredSurveys() {
            if (this.onlyUnseen) {
                return this.surveys.filter((survey) => !survey.seen);
            } else {
                return this.surveys;
            }
        },
    },
    data() {
        return {
            onlyUnseen: true,
            headers: [
                { text: "Usuario", value: "user" },
                { text: "Rating", value: "rating" },
                { text: "Fecha", value: "date" },
            ],
            loading: false,
            surveyDetailsDialog: false,
            selectedSurvey: null,
            comment: null,
            rating: null,
            surveys: [],
            isAdmin: this.$store.state.Auth.token.claims.type == 'superuser',
            rules: [
                (v) => !!v || "El comentario es requerido",
                (v) => (v && v.length <= 500) || "El comentario debe tener menos de 500 caracteres",
            ],
            rulesRating: [
                (v) => !!v || "El rating es requerido",
            ],
            file: null, // New property to hold the selected file
        };
    },
    created() {},
    mounted() {
        this.$vuetify.goTo(0);
    },
    methods: {
        async submitSurvey() {
            let userId = this.$store.state.Auth.token.claims.user_id;
            let userType = this.$store.state.Auth.token.claims.type;

            if (this.$refs.form.validate() == false) {
                return;
            }

            this.loading = true;
            const db = getFirestore();
            const storage = getStorage();
            let fileUrl = null;

            if (this.file) {
                const storageRef = ref(storage, `issues/${this.file.name}`);
                const snapshot = await uploadBytes(storageRef, this.file);
                fileUrl = await getDownloadURL(snapshot.ref);
            }

            const timestamp = serverTimestamp();
            await addDoc(collection(db, `issues`), {
                seen: false,
                user: userId,
                comment: this.comment,
                userType: userType,
                date: timestamp,
                extraInfo: getUserInfo(),
                fileUrl: fileUrl, // Store the file URL
            });

            this.loading = false;

            this.$notify({
                group: "feedback",
                type: "success",
                title: "Incidencia reportada",
                text: "Gracias por su colaboración",
            });

            this.comment = null;
            this.rating = null;
            this.file = null; // Reset the file input
            this.$router.push("/");
        },
        returnColor(rating) {
            if (rating == 1) {
                return "red darken-2";
            } else if (rating == 2) {
                return "orange darken-2";
            } else if (rating == 3) {
                return "grey lighten-2";
            } else if (rating == 4) {
                return "green darken-2";
            } else if (rating == 5) {
                return "yellow darken-1";
            }
        },
    },
};
</script>
